






















































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { EmailNotificationTypeDocument } from '@/api/enums/emailNotificationTypeDocument'
import { EmailNotificationType } from '@/api/enums/emailNotificationType'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import Mustache from '@/plugins/mustache'
import { NotificationEmailAttestationCompany } from '@/enums/notificationEmailAttestationCompany'
import { NotificationEmailConvocationCompany } from '@/enums/notificationEmailConvocationCompany'
import { format, parseJSON } from 'date-fns'
import Loading from '@/components/base/Loading.vue'

export default defineComponent({
  name: 'SendSessionDocumentDialog',
  components: { Loading },
  props: {
    title: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    logisticSession: {
      type: Object,
      required: true,
    },
    trainingSession: {
      type: Object,
      required: true,
    },
    needTrainings: {
      type: Array,
      required: true,
    },
    typeDocument: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isSubmiting: false,
    isLoadingNotificationEmail: false,
    object: '',
    content: '',
    itemsCC: [] as Array<any>,
    itemsCCI: [] as Array<any>,
    isMentionClimate: false,
    emailSender: '',
  }),
  created () {
    this.init()
  },
  watch: {
    needTrainings () {
      this.init()
    },
  },
  methods: {
    ...mapActions('emailSessionDocument', {
      saveEmailSessionDocument: 'save',
    }),
    ...mapActions('configuration', {
      getEmailSenderByTrainingSession: 'getEmailSenderByTrainingSession',
    }),
    ...mapActions('notificationEmail', {
      getNotificationEmailByTrainingSession: 'getNotificationEmailByTrainingSession',
    }),
    async init () {
      if (this.needTrainings.length === 0) {
        return
      }
      this.isLoadingNotificationEmail = true
      this.isMentionClimate = false
      this.itemsCC = []
      this.itemsCCI = []
      this.emailSender = await this.getEmailSenderByTrainingSession({
        trainingSession: this.trainingSession as TrainingSession,
        logisticSession: this.logisticSession as LogisticSession,
      })
      const notificationEmail = await this.getNotificationEmailByTrainingSession({
        trainingSession: this.trainingSession,
        logisticSession: this.logisticSession,
        emailNotificationTypeDocument: this.typeDocument,
        emailNotificationType: EmailNotificationType.COMPANY,
      })
      if (notificationEmail) {
        if (this.isDocumentAttestation) {
          this.object = Mustache.render(notificationEmail.object, {
            [NotificationEmailAttestationCompany.COMPANY_NAME]: this.getCompanyName,
            [NotificationEmailAttestationCompany.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
          })
          this.content = Mustache.render(notificationEmail.content, {
            [NotificationEmailAttestationCompany.COMPANY_NAME]: this.getCompanyName,
            [NotificationEmailAttestationCompany.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
          })
        } else if (this.isDocumentConvocation) {
          const trainingSessionStartAt = format(parseJSON(this.trainingSession.startAt), 'dd/MM/yyyy')
          this.object = Mustache.render(notificationEmail.object, {
            [NotificationEmailConvocationCompany.COMPANY_NAME]: this.getCompanyName,
            [NotificationEmailConvocationCompany.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
            [NotificationEmailConvocationCompany.TRAINING_LOCATION_ADDRESS]: this.trainingSession.trainingLocation.address,
            [NotificationEmailConvocationCompany.TRAINING_LOCATION_CITY]: this.trainingSession.trainingLocation.city,
            [NotificationEmailConvocationCompany.TRAINING_LOCATION_ZIP_CODE]: this.trainingSession.trainingLocation.zipCode,
            [NotificationEmailConvocationCompany.TRAINING_SESSION_DATE]: trainingSessionStartAt,
          })
          this.content = Mustache.render(notificationEmail.content, {
            [NotificationEmailConvocationCompany.COMPANY_NAME]: this.getCompanyName,
            [NotificationEmailConvocationCompany.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
            [NotificationEmailConvocationCompany.TRAINING_LOCATION_ADDRESS]: this.trainingSession.trainingLocation.address,
            [NotificationEmailConvocationCompany.TRAINING_LOCATION_CITY]: this.trainingSession.trainingLocation.city,
            [NotificationEmailConvocationCompany.TRAINING_LOCATION_ZIP_CODE]: this.trainingSession.trainingLocation.zipCode,
            [NotificationEmailConvocationCompany.TRAINING_SESSION_DATE]: trainingSessionStartAt,
          })
        }
      }
      this.isLoadingNotificationEmail = false
    },
    onClose () {
      this.$emit('close')
    },
    addItemsCC (item: any) {
      this.itemsCC.push(item)
    },
    deleteItemCC: function (index: any) {
      this.itemsCC.splice(index, 1)
      if (this.itemsCC.length === 0) {
        this.addItemsCC({ email: '' })
      }
    },
    addItemsCCI (item: any) {
      this.itemsCCI.push(item)
    },
    deleteItemCCI: function (index: any) {
      this.itemsCCI.splice(index, 1)
      if (this.itemsCCI.length === 0) {
        this.addItemsCCI({ email: '' })
      }
    },
    async onSubmit () {
      try {
        this.isSubmiting = true
        for (const needTraining of (this.needTrainings as Array<NeedTraining>)) {
          const emailSessionDocument = {
            trainingSession: `/api/training_sessions/${(this.trainingSession as TrainingSession).id}`,
            needTraining: `/api/need_trainings/${needTraining.id}`,
            title: this.object,
            content: this.content,
            typeDocument: this.typeDocument,
            receiver: needTraining.trainee.email,
            carbonCopy: this.itemsCC,
            blindCarbonCopy: this.itemsCCI,
            isMentionClimate: this.isMentionClimate,
            emailNotificationType: EmailNotificationType.COMPANY,
            sender: this.emailSender,
          }
          await this.saveEmailSessionDocument(emailSessionDocument)
        }
      } finally {
        this.isSubmiting = false
        this.$emit('close')
        this.$emit('send')
      }
    },
  },
  computed: {
    headersTrainees (): Array<any> {
      return [
        {
          text: '',
          align: 'center',
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainee.lastName'),
          align: 'center',
          value: 'trainee.lastName',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainee.firstName'),
          align: 'center',
          value: 'trainee.firstName',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainee.email'),
          align: 'center',
          value: 'trainee.email',
          sortable: false,
        },
      ]
    },
    getCompanyName (): string {
      return this.needTrainings.length > 0 ? (this.needTrainings as Array<NeedTraining>)[0].company.name : ''
    },
    isDocumentAttestation (): boolean {
      return this.typeDocument === EmailNotificationTypeDocument.ATTESTATION
    },
    isDocumentConvocation (): boolean {
      return this.typeDocument === EmailNotificationTypeDocument.CONVOCATION
    },
  },
})
